<template>
  <div class="page-content-inner pt-lg-0 pr-lg-0 mt-4">
    <div class="page-content-inner pt-lg-0 pr-lg-0 mt-4">
      <div class="uk-grid-medium" uk-grid>
        <div class="uk-width-5-5">
          <div class="d-flex">
            <nav id="breadcrumbs" class="mb-3">
              <ul>
                <li>
                  <a href="#"> <i class="uil-home-alt"></i> </a>
                </li>
                <li>Bildirimler</li>
              </ul>
            </nav>
          </div>
          <ul
            id="notifications"
            class="uk-tab"
            uk-switcher="animation: uk-animation-fade"
          >
            <li>
              <a href="#"
                ><h4><i class="uil-bell"></i> Bildirim & Duyurular</h4></a
              >
            </li>
            <!-- <li><a href="#"><h4><i class="uil-megaphone"></i> Duyurular</h4></a></li> -->
            <li>
              <a href="#"
                ><h4>
                  <i class="uil-circle-layer"></i> Bekleyen Aksiyonlarım
                </h4></a
              >
            </li>
          </ul>
          <template v-if="errorMessages">
            <div
              v-for="(error, index) in errorMessages"
              :key="index"
              class="uk-alert-danger"
              uk-alert
            >
              <a class="uk-alert-close" uk-close></a>
              <p style="color: red">
                <i class="uil-exclamation-triangle"></i> {{ error }}
              </p>
            </div>
          </template>
          <ul class="uk-switcher">
            <!-- tab: bildirimlerim -->
            <li>
              <div class="chats-container margin-top-0">
                <div class="chats-container-inner">
                  <div class="chats-inbox for_notice" style="z-index: 2">
                    <div class="chats-headline">
                      <div class="input-with-icon">
                        <input
                          id="autocomplete-input"
                          placeholder="Bildirimler ve Duyurularda Ara"
                          type="text"
                          v-model="notification_filters.search"
                          @input="notification_filters.isTyping = true"
                        />
                        <i class="icon-material-outline-search"></i>
                      </div>
                    </div>
                    <ul>
                      <li
                        class="p-3 d-flex disabled_hover"
                        :key="'notification-actions'"
                      >
                        <button
                          class="btn btn-sm btn-soft-secondary"
                          type="button"
                          @click="markAllNotificationsAsRead(0)"
                          :key="'notification-mark'"
                        >
                          <i class="uil-envelope-open"></i> Tümünü Okundu Yap
                        </button>
                        <button
                          class="btn btn-sm btn-soft-info ml-2"
                          type="button"
                          @click="
                            selectAllNotifications(
                              0,
                              notification_checked.length
                            )
                          "
                          :key="'select-all'"
                        >
                          <i class="uil-list-ul"></i> {{ allNotificationText }}
                        </button>
                        <button
                          class="btn btn-sm btn-soft-danger ml-2"
                          type="button"
                          @click="deleteCheckedNotifications(0)"
                          :key="'notification-delete'"
                        >
                          <i class="uil-trash"></i> Seçileni Sil
                        </button>
                      </li>
                      <div v-show="notif_load">
                        <default-loading></default-loading>
                      </div>
                      <template
                        v-show="!notif_load"
                        v-for="(notification, index) in notifications"
                      >
                        <li
                          v-if="'notification_list_item_' + notification.id"
                          :key="'notification_list_item_' + notification.id"
                        >
                          <a
                            href="javascript:void(0)"
                            :style="
                              notification_selected?.id === notification?.id
                                ? 'background:#edeff0'
                                : ''
                            "
                          >
                            <div class="d-flex">
                              <input
                                v-model="notification_checked"
                                :id="'notification_checkbox_' + index"
                                :value="notification"
                                style="width: 15px; margin: 10px 10px 10px 0"
                                type="checkbox"
                              />
                              <span
                                :class="
                                  notification.is_read ? 'disabled' : 'active'
                                "
                                class="notification-icon btn btn-soft-info mr-3"
                                @click="markNotificationAsRead(index, 0)"
                              >
                                <i class="icon-feather-bell"></i>
                              </span>
                              <div
                                class="message-by-headline"
                                @click="markNotificationAsRead(index, 0)"
                              >
                                <h5
                                  :class="
                                    notification.is_read
                                      ? 'text-muted'
                                      : 'text-dark'
                                  "
                                >
                                  <template
                                    v-if="
                                      typeof notification.parsed_data
                                        .message !== 'undefined'
                                    "
                                  >
                                    {{ notification.parsed_data.message }}
                                  </template>
                                  <template v-else>
                                    <ul style="overflow:hidden;">
                                      <li
                                        v-for="(messageText,
                                        messageKey) in notification.parsed_data"
                                      >
                                        {{ messageKey }}: {{ messageText }}
                                      </li>
                                    </ul>
                                  </template>
                                </h5>
                              </div>
                            </div>
                          </a>
                        </li>
                      </template>

                      <!-- <li class="">
                        <a href="#">
                          <div class="d-flex">
                            <input
                              name="mail"
                              style="width: 15px; margin: 10px 10px 10px 0px"
                              type="checkbox"
                            />
                            <span
                              class="notification-icon btn btn-soft-info mr-3"
                            >
                              <i class="uil-megaphone"></i
                            ></span>
                            <div class="message-by-headline">
                              <h5>Önemli Duyuru</h5>
                              <p>
                                Lorem ipsum dolor sit amet consectetur
                                adipisicing elit...
                              </p>
                            </div>
                          </div>
                        </a>
                      </li>  -->
                    </ul>
                  </div>
                  <!-- chats / End -->
                  <!-- Message Content -->
                  <div class="message-content" style="z-index: 1">
                    <default-loading
                      v-if="notification_loading"
                    ></default-loading>
                    <!-- Message Content Inner -->
                    <div
                      class="message-content-inner"
                      v-if="!notification_loading && notification_selected"
                    >
                      <h4>
                        <template
                          v-if="
                            typeof notification_selected.parsed_data.message !==
                              'undefined'
                          "
                        >
                          <strong>{{
                            notification_selected.parsed_data.message
                          }}</strong>
                        </template>
                        <template
                          v-if="
                            typeof notification_selected.parsed_data.text !==
                              'undefined'
                          "
                        >
                          <div
                            class="mt-5"
                            v-html="notification_selected.parsed_data.text"
                          ></div>
                        </template>
                        <!--                        <template v-else>-->
                        <!--                          <ul style="overflow:hidden;">-->
                        <!--                            <li v-for="(messageText, messageKey) in notification_selected.parsed_data">-->
                        <!--                              {{ messageKey }}: {{ messageText }}-->
                        <!--                            </li>-->
                        <!--                          </ul>-->
                        <!--                        </template>-->
                      </h4>

                      <span>
                        <i>{{
                          fixDateToNow(notification_selected.created_at)
                        }}</i>
                      </span>

                      <!--                      <p class="mt-4" style="font-size: 0.875rem; line-height: 1.6">-->
                      <!--                        {{ notification_selected.parsed_data.message }}-->
                      <!--                      </p>-->
                      <!-- <p><a class="btn btn-success btn-xl" href="">Eğitime Git</a></p> -->
                    </div>
                    <!-- Message Content Inner / End -->
                  </div>
                  <!-- Message Content -->
                </div>
              </div>
            </li>

            <!-- tab: aksiyonlar -->
            <li>
              <div class="chats-container margin-top-0">
                <div class="chats-container-inner">
                  <div class="chats-inbox for_notice">
                    <div class="chats-headline">
                      <div class="input-with-icon">
                        <input
                          id="autocomplete-input2"
                          placeholder="Aksiyonlarda Ara"
                          type="text"
                          v-model="action_filters.search"
                          @input="action_filters.isTyping = true"
                        />
                        <i class="icon-material-outline-search"></i>
                      </div>
                    </div>
                    <ul>
                      <li class="p-3 d-flex" :key="'action-actions'">
                        <button
                          class="btn btn-sm btn-soft-secondary"
                          type="button"
                          @click="markAllNotificationsAsRead(1)"
                          :key="'action-mark'"
                        >
                          <i class="uil-envelope-open"></i> Tümünü Okundu Yap
                        </button>
                        <button
                          class="btn btn-sm btn-soft-info ml-2"
                          type="button"
                          @click="
                            selectAllNotifications(1, action_checked.length)
                          "
                          :key="'select-all'"
                        >
                          <i class="uil-list-ul"></i> {{ allActionText }}
                        </button>
                        <button
                          class="btn btn-sm btn-soft-danger ml-2"
                          type="button"
                          @click="deleteCheckedNotifications(1)"
                          :key="'action-delete'"
                        >
                          <i class="uil-trash"></i> Seçileni Sil
                        </button>
                      </li>

                      <template v-for="(action, index) in actions">
                        <li
                          v-if="'action_list_item_' + action.id"
                          :key="'action_list_item_' + action.id"
                        >
                          <a
                            href="javascript:void(0)"
                            :style="
                              action_selected.id === action.id
                                ? 'background:#edeff0'
                                : ''
                            "
                          >
                            <div class="d-flex">
                              <input
                                v-model="action_checked"
                                :id="'actions_checkbox_' + index"
                                :value="action.id"
                                style="width: 15px; margin: 10px 10px 10px 0"
                                type="checkbox"
                              />
                              <span
                                :class="action.is_read ? 'disabled' : 'active'"
                                class="notification-icon btn btn-soft-info mr-3"
                                @click="markNotificationAsRead(index, 1)"
                              >
                                <i class="icon-feather-bell"></i>
                              </span>
                              <div
                                class="message-by-headline"
                                @click="markNotificationAsRead(index, 1)"
                              >
                                <h5
                                  :class="
                                    action.is_read ? 'text-muted' : 'text-dark'
                                  "
                                >
                                  <template
                                    v-if="
                                      typeof action.parsed_data.message !==
                                        'undefined'
                                    "
                                  >
                                    {{ action.parsed_data.message }}
                                  </template>
                                  <template v-else>
                                    <ul style="overflow:hidden;">
                                      <li
                                        v-for="(messageText,
                                        messageKey) in action.parsed_data"
                                      >
                                        {{ messageKey }}: {{ messageText }}
                                      </li>
                                    </ul>
                                  </template>
                                </h5>
                              </div>
                            </div>
                          </a>
                        </li>
                      </template>

                      <!--                      <li class="">-->
                      <!--                        <a href="#">-->
                      <!--                          <div class="d-flex">-->
                      <!--                            <input-->
                      <!--                              name="mail"-->
                      <!--                              style="width: 15px; margin: 10px 10px 10px 0px"-->
                      <!--                              type="checkbox"-->
                      <!--                            />-->
                      <!--                            <span-->
                      <!--                              class="-->
                      <!--                                notification-icon-->
                      <!--                                btn btn-soft-primary-->
                      <!--                                disabled-->
                      <!--                                mr-3-->
                      <!--                              "-->
                      <!--                            >-->
                      <!--                              <i class="uil-circle-layer"></i-->
                      <!--                            ></span>-->
                      <!--                            <div class="message-by-headline">-->
                      <!--                              <h5>-->
                      <!--                                Tamamlaman Gereken Eğitimin Var: Uzaktan Çalışma-->
                      <!--                                İpuçları-->
                      <!--                              </h5>-->
                      <!--                              <p>-->
                      <!--                                Lorem ipsum dolor sit amet, consectetur-->
                      <!--                                adipisicing elit...-->
                      <!--                              </p>-->
                      <!--                            </div>-->
                      <!--                          </div>-->
                      <!--                        </a>-->
                      <!--                      </li>-->

                      <!--                      <li class="active-message">-->
                      <!--                        <a href="#">-->
                      <!--                          <div class="d-flex">-->
                      <!--                            <input-->
                      <!--                              name="mail"-->
                      <!--                              style="width: 15px; margin: 10px 10px 10px 0px"-->
                      <!--                              type="checkbox"-->
                      <!--                            />-->
                      <!--                            <span-->
                      <!--                              class="-->
                      <!--                                notification-icon-->
                      <!--                                btn btn-soft-primary-->
                      <!--                                disabled-->
                      <!--                                mr-3-->
                      <!--                              "-->
                      <!--                            >-->
                      <!--                              <i class="uil-circle-layer"></i>-->
                      <!--                            </span>-->
                      <!--                            <div class="message-by-headline">-->
                      <!--                              <h5>-->
                      <!--                                Bekleyen Değerlendirme Anketin Var: İş Sağlığı-->
                      <!--                                Güvenliği-->
                      <!--                              </h5>-->
                      <!--                              <p>-->
                      <!--                                Lorem ipsum dolor sit amet, consectetur-->
                      <!--                                adipisicing elit...-->
                      <!--                              </p>-->
                      <!--                            </div>-->
                      <!--                          </div>-->
                      <!--                        </a>-->
                      <!--                      </li>-->

                      <!--                      <li class="">-->
                      <!--                        <a href="#">-->
                      <!--                          <div class="d-flex">-->
                      <!--                            <input-->
                      <!--                              name="mail"-->
                      <!--                              style="width: 15px; margin: 10px 10px 10px 0px"-->
                      <!--                              type="checkbox"-->
                      <!--                            />-->
                      <!--                            <span-->
                      <!--                              class="-->
                      <!--                                notification-icon-->
                      <!--                                btn btn-soft-primary-->
                      <!--                                disabled-->
                      <!--                                mr-3-->
                      <!--                              "-->
                      <!--                            >-->
                      <!--                              <i class="uil-circle-layer"></i-->
                      <!--                            ></span>-->
                      <!--                            <div class="message-by-headline">-->
                      <!--                              <h5>Düello Davetin Var: Selin Güzelyurt</h5>-->
                      <!--                              <p>-->
                      <!--                                Lorem ipsum dolor sit amet, consectetur-->
                      <!--                                adipisicing elit...-->
                      <!--                              </p>-->
                      <!--                            </div>-->
                      <!--                            <span class="message-readed uil-check"> </span>-->
                      <!--                          </div>-->
                      <!--                        </a>-->
                      <!--                      </li>-->
                    </ul>
                  </div>
                  <!-- chats / End -->
                  <!-- Message Content -->
                  <div class="message-content" style="z-index: 1">
                    <default-loading
                      v-if="notification_loading"
                    ></default-loading>
                    <!-- Message Content Inner -->
                    <div
                      class="message-content-inner"
                      v-if="!notification_loading && action_selected"
                    >
                      <h4>
                        <template
                          v-if="
                            typeof action_selected.parsed_data.message !==
                              'undefined'
                          "
                        >
                          <strong>{{
                            action_selected.parsed_data.message
                          }}</strong>
                        </template>
                        <template
                          v-if="
                            typeof action_selected.parsed_data.text !==
                              'undefined'
                          "
                        >
                          <div
                            class="mt-5"
                            v-html="action_selected.parsed_data.text"
                          ></div>
                        </template>
                        <!--                        <template v-else>-->
                        <!--                          <ul style="overflow:hidden;">-->
                        <!--                            <li v-for="(messageText, messageKey) in notification_selected.parsed_data">-->
                        <!--                              {{ messageKey }}: {{ messageText }}-->
                        <!--                            </li>-->
                        <!--                          </ul>-->
                        <!--                        </template>-->
                      </h4>

                      <span>
                        <i>{{ fixDateToNow(action_selected.created_at) }}</i>
                      </span>

                      <!--                      <p class="mt-4" style="font-size: 0.875rem; line-height: 1.6">-->
                      <!--                        {{ notification_selected.parsed_data.message }}-->
                      <!--                      </p>-->
                      <!-- <p><a class="btn btn-success btn-xl" href="">Eğitime Git</a></p> -->
                    </div>
                    <!-- Message Content Inner / End -->
                  </div>
                  <!-- Message Content -->
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardLayout from "@/view/layouts/DashboardLayout";
import NotificationItem from "@/view/components/notification/NotificationItem";
import moment from "moment";
import store from "@/core/services/index";
import {
  GET_ITEMS,
  ITEMS,
  UPDATE_ITEM_BY_ID,
  ERROR,
  DELETE_ITEM_BY_ID,
  LOADING,
} from "@/core/services/store/REST.module";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import notification_module, {
  COUNT as NOTIFICATION_COUNT,
  GET_COUNT as NOTIFICATION_GET_COUNT,
  MODULE_NAME as NOTIFICATION_MODULE_NAME,
} from "@/core/services/store/notification.module";

export default {
  name: "Notification",
  components: {
    DashboardLayout,
    NotificationItem,
    DefaultLoading,
  },
  props: {
    selected_notice: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      notif_load: true,
      notificationUrl: "api/notifications",
      notificationMarkAsReadUrl: "api/notification-update",
      noticeMarkAsReadUrl: "api/notice-update",
      notificationDeleteCheckedUrl: "api/notification-delete",
      noticeDeleteCheckedUrl: "api/notice-delete",
      notifications: [],
      notification_selected: null,
      notification_checked: [],
      notification_filters: {
        search: "",
        isTyping: false,
      },
      allNotificationText: "Tümünü Seç",
      allActionText: "Tümünü Seç",
      actions: [],
      action_selected: null,
      action_checked: [],
      action_filters: {
        is_action: 1,
        search: "",
        isTyping: false,
      },

      errorMessages: [],
    };
  },
  computed: {
    notification_loading: {
      get() {
        return this.$store.getters[LOADING];
      },
      set(value) {},
    },
    error: {
      get() {
        return this.$store.getters[ERROR];
      },
      set(value) {},
    },
  },
  methods: {
    selectAllNotifications(is_action, length) {
      let self = this;
      if (is_action === 0) {
        if (self.notification_checked.length == self.notifications.length) {
          self.notification_checked = [];
          self.allNotificationText = "Tümünü Seç";
          return false;
        }
        self.notification_checked = [];
        self.notifications.forEach((element) => {
          self.notification_checked.push(element);
          self.allNotificationText = "Temizle";
        });
      } else {
        if (self.action_checked.length == self.actions.length) {
          self.action_checked = [];
          self.allActionText = "Tümünü Seç";
          return false;
        }
        self.action_checked = [];
        self.actions.forEach((element) => {
          self.action_checked.push(element.id);
          self.allActionText = "Temizle";
        });
      }
    },
    getNotifications() {
      let self = this;
      self.notif_load = true;
      this.$store
        .dispatch(GET_ITEMS, {
          url: this.notificationUrl,
          filters: {
            search: this.notification_filters.search,
          },
          acceptPromise: false,
        })
        .then((result) => {
          if (typeof result.data !== "undefined") {
            let formattedItems = [];

            result.data.forEach((item) => {
              if (item.type == 1) {
                item.parsed_data = JSON.parse(item.data);
                item.is_read = !(item.read_at === null || item.read_at === "");
                formattedItems.push(item);
              } else {
                item.parsed_data = {
                  message: item.name,
                  text: item.text,
                  webUrl: null,
                };
                item.is_read = item.pivot.is_read;
                formattedItems.push(item);
              }
            });
            self.notifications = formattedItems;

            if (self.$route.params.id != undefined) {
              let go_index = formattedItems.findIndex(
                (obj) => obj.id === self.$route.params.id
              );
              this.markNotificationAsRead(go_index, 0);
            } else {
              this.markNotificationAsRead(0, 0);
            }

            self.notif_load = false;
          }
        });
    },
    markNotificationAsRead(index, is_action) {
      let self = this;
      if (is_action === 0) {
        let notification = self.notifications[index];
        if (typeof self.notifications[index] === "undefined") return;

        self.notification_selected = notification;
        let type_one = [];
        let type_two = [];
        if (notification.type == 1) {
          type_one.push(notification.id);
        } else if (notification.type == 2) {
          type_two.push(notification.id);
        }
        if (type_one.length > 0) {
          self.$store
            .dispatch(UPDATE_ITEM_BY_ID, {
              url: self.notificationMarkAsReadUrl,
              contents: { notification_id: type_one },
              acceptPromise: false,
            })
            .then((response) => {
              self.$store.dispatch(
                NOTIFICATION_MODULE_NAME + "/" + NOTIFICATION_GET_COUNT,
                {}
              );

              if (response.status) {
                notification.is_read = true;
              } else {
                self.errorMessages.push(response.data);
              }
            });
        }
        if (type_two.length > 0) {
          self.$store
            .dispatch(UPDATE_ITEM_BY_ID, {
              url: self.noticeMarkAsReadUrl,
              contents: { notification_id: type_two },
              acceptPromise: false,
            })
            .then((response) => {
              if (response.status) {
                notification.is_read = true;
              } else {
                self.errorMessages.push(response.data);
              }
            });
        }
      }
      if (is_action === 1) {
        let action = self.actions[index];
        if (typeof self.actions[index] === "undefined") return;

        self.action_selected = action;
        let type_one = [];
        let type_two = [];
        if (action.type == 1) {
          type_one.push(action.id);
        } else if (action.type == 2) {
          type_two.push(action.id);
        }
        if (type_one.length > 0) {
          self.$store
            .dispatch(UPDATE_ITEM_BY_ID, {
              url: self.notificationMarkAsReadUrl,
              contents: { notification_id: type_one },
              acceptPromise: false,
            })
            .then((response) => {
              if (response.status) {
                action.is_read = true;
              } else {
                self.errorMessages.push(response.data);
              }
            });
        }
        if (type_two.length > 0) {
          self.$store
            .dispatch(UPDATE_ITEM_BY_ID, {
              url: self.noticeMarkAsReadUrl,
              contents: { notification_id: type_two },
              acceptPromise: false,
            })
            .then((response) => {
              if (response.status) {
                action.is_read = true;
              } else {
                self.errorMessages.push(response.data);
              }
            });
        }
      }
    },
    markAllNotificationsAsRead(is_action) {
      let self = this;
      if (is_action === 0) {
        self.$store
          .dispatch(UPDATE_ITEM_BY_ID, {
            url: self.notificationMarkAsReadUrl,
            contents: { notification_id: "all", is_action: is_action },
            acceptPromise: false,
          })
          .then((response) => {
            if (response.status) {
              self.notifications.forEach((notification) => {
                notification.is_read = true;
              });
            } else {
              self.errorMessages.push(response.data);
            }
          });

        self.$store
          .dispatch(UPDATE_ITEM_BY_ID, {
            url: self.noticeMarkAsReadUrl,
            contents: { notification_id: "all" },
            acceptPromise: false,
          })
          .then((response) => {
            if (response.status) {
              self.notifications.forEach((notification) => {
                notification.is_read = true;
              });
            } else {
              self.errorMessages.push(response.data);
            }
          });
      }
      if (is_action === 1) {
        self.$store
          .dispatch(UPDATE_ITEM_BY_ID, {
            url: self.notificationMarkAsReadUrl,
            contents: { notification_id: "all", is_action: is_action },
            acceptPromise: false,
          })
          .then((response) => {
            if (response.status) {
              self.actions.forEach((action) => {
                action.is_read = true;
              });
            } else {
              self.errorMessages.push(response.data);
            }
          });
        self.$store
          .dispatch(UPDATE_ITEM_BY_ID, {
            url: self.noticeMarkAsReadUrl,
            contents: { notification_id: "all" },
            acceptPromise: false,
          })
          .then((response) => {
            if (response.status) {
              self.notifications.forEach((notification) => {
                notification.is_read = true;
              });
            } else {
              self.errorMessages.push(response.data);
            }
          });
      }
    },
    deleteCheckedNotifications(is_action) {
      let self = this;
      if (is_action === 0) {
        if (!self.notification_checked.length) {
          self.errorMessages.push("Herhangi bir duyuru seçilmedi.");
          return true;
        }
        let type_one = [];
        let type_two = [];
        self.notification_checked.forEach((element) => {
          if (element.type == 1) {
            type_one.push(element.id);
          } else if (element.type == 2) {
            type_two.push(element.id);
          }
        });
        if (type_one.length > 0) {
          self.$store
            .dispatch(UPDATE_ITEM_BY_ID, {
              url: self.notificationDeleteCheckedUrl,
              contents: { notification_id: type_one, is_action: is_action },
              acceptPromise: false,
            })
            .then((response) => {
              if (response.status) {
                self.getNotifications();
                self.notification_checked = [];
                self.markNotificationAsRead(0, 0);
              } else {
                self.errorMessages.push(response.data);
              }
            });
        }
        if (type_two.length > 0) {
          self.$store
            .dispatch(UPDATE_ITEM_BY_ID, {
              url: self.noticeDeleteCheckedUrl,
              contents: { notification_id: type_two },
              acceptPromise: false,
            })
            .then((response) => {
              if (response.status) {
                self.getNotifications();
                self.notification_checked = [];
                self.markNotificationAsRead(0, 0);
              } else {
                self.errorMessages.push(response.data);
              }
            });
        }
      }

      if (is_action === 1) {
        if (!self.action_checked.length) {
          self.errorMessages.push("Herhangi bir aksiyon seçilmedi.");
          return true;
        }
        self.$store
          .dispatch(UPDATE_ITEM_BY_ID, {
            url: self.notificationDeleteCheckedUrl,
            contents: {
              notification_id: self.action_checked,
              is_action: is_action,
            },
            acceptPromise: false,
          })
          .then((response) => {
            if (response.status) {
              self.getActions();
              self.action_checked = [];
              self.markNotificationAsRead(0, 1);
            } else {
              self.errorMessages.push(response.data);
            }
          });
      }
    },
    getActions() {
      let self = this;
      this.$store
        .dispatch(GET_ITEMS, {
          url: this.notificationUrl,
          filters: {
            is_action: this.action_filters.is_action,
            search: this.action_filters.search,
          },
          acceptPromise: false,
        })
        .then((result) => {
          if (typeof result.data !== "undefined") {
            let formattedItems = [];

            result.data.forEach((item) => {
              if (item.type == 1) {
                item.parsed_data = JSON.parse(item.data);
                item.is_read = !(item.read_at === null || item.read_at === "");
                formattedItems.push(item);
              } else {
                item.parsed_data = {
                  message: item.name,
                  text: item.text,
                  webUrl: null,
                };
                item.is_read = item.pivot.is_read;
                formattedItems.push(item);
              }
            });
            self.actions = formattedItems;

            this.markNotificationAsRead(0, 1);
          }
        });
    },
    getResults() {
      this.getNotifications();
      this.getActions();
    },
    fixDateToNow(value) {
      return moment(value).fromNow();
    },
    resetMessages() {
      this.errorMessages = [];
    },
  },
  mounted() {
    this.getResults();
  },
  created: function() {
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  },
  watch: {
    "notification_filters.search": _.debounce(function() {
      this.notification_filters.isTyping = false;
    }, 1000),
    "notification_filters.isTyping": function(value) {
      if (!value) this.getNotifications();
    },
    notifications: function(value) {
      let itemId = this.$route.params.id;
      if (itemId && value) {
        let notificationItem = value.findIndex((item) => item.id === itemId);
        if (notificationItem) {
          this.markAllNotificationsAsRead(notificationItem, 0);
        }
      }
    },
    actions: function(value) {
      let itemId = this.$route.params.id;
      if (itemId && value) {
        let notificationItem = value.findIndex((item) => item.id === itemId);
        if (notificationItem) {
          this.markAllNotificationsAsRead(notificationItem, 0);
        }
      }
    },
    "action_filters.search": _.debounce(function() {
      this.action_filters.isTyping = false;
    }, 1000),
    "action_filters.isTyping": function(value) {
      if (!value) this.getActions();
    },
    errorMessages() {
      this.debouncedResetValues();
    },
  },
};
</script>

<style scoped>
.chats-inbox ul li.disabled_hover:before {
  display: none;
}
input[type="checkbox"]:focus {
  box-shadow: none !important;
}
</style>
